<script lang="ts" setup>
// Stores
const { areas } = storeToRefs(areaStore());
</script>
<template>
  <div class="gap-4 grid">
    <ui-warning v-if="globalStore().getSelectedSite.length > 1" :message="$t('area.warning_message_all_sites_selected')" />
    <div v-if="globalStore().getSelectedSite.length === 1" class="bg-white flex flex-col rounded-lg border border-gray-200">
      <div class="p-4 border-b border-gray-200 flex items-center justify-between">
        <p class="text-base font-semibold text-gray-900">
          {{ $t('global.areas') }}
        </p>
        <div class="flex items-center flex-row gap-x-2">
          <ui-button left-icon="Plus" @click="$router.push('/configuration/areas/create')">
            {{ $t('area.add_area') }}
          </ui-button>
        </div>
      </div>
      <div>
        <ui-chart-flow :areas="areas" @click-on-area="$router.push(`/configuration/areas/edit/${$event}`)" />
      </div>
    </div>
  </div>
</template>
